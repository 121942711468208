.page-update-location {
  .page-update-location__field {
    margin-bottom: 0.5rem;
  }

  .page-update-location__status {
    margin: 1rem 0;
    display: flex;
    flex-direction: row-reverse;
  }

  .page-update-location__status-action {
    display: inline-block;
  }
}
