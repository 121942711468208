@import "@mypz/react-kit/components/mypz.scss";

.page-locations-list {
  .page-locations-list__actions {
    display: flex;
    justify-content: space-between;
  }

  .page-locations-list__search-form {
    padding-top: 1rem;
  }

  .page-locations-list__search-field {
    padding-bottom: 1rem;
  }

  .page-locations-list__see-details {
    line-height: 0.5rem;
    display: flex;
  }

  .page-locations-list__pagination-block {
    margin: 0.2rem auto;
    display: flex;

    .page-locations-list__pagination-block__total{
      font-size: 13px;
      font-weight: bold;
      align-self: center;
    }

    .page-locations-list__pagination-block__selector {
      margin: auto;
    }
  }
}