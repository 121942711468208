@import "@mypz/react-kit/components/mypz.scss";

.form-ad__field {
  margin-bottom: 0.5rem;
}

.form-ad__field__label {
  font-size: inherit;
  font-weight: bold;
  color: $color-third;
  padding-bottom: 0.3rem;
}