html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100vh;
}

.App {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

a, a:visited {
  text-decoration: none;
  color: inherit;
}

li, ul {
  list-style: none;
}

input, button, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
