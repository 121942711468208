.page-update-ad {
  .page-update-ad__field {
    margin-bottom: 0.5rem;
  }

  .page-update-ad__status {
    margin: 1rem 0;
    display: flex;
    flex-direction: row-reverse;
  }

  .page-update-ad__status-action {
    display: inline-block;
  }
}
