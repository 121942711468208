@import "@mypz/react-kit/components/mypz.scss";

.form-location__field {
  margin-bottom: 0.5rem;
}

.form-location__field__label {
  font-size: inherit;
  font-weight: bold;
  color: $color-third;
  padding-bottom: 0.3rem;
}

.form-location__main-location {
  padding: 1rem;
  border: solid 1px grey;
}

.form-location__main-location-selected {
  display: flex;

  label {
    font-weight: bold;
    margin-right: 0.5rem;
  }
}

.form-location__main-location-option {
  margin-bottom: 0.1rem;
}

.form-location__main-location-searchable-none {
  color: red;
}

.form-location__main-location-searchable-child {
  color: blue;
}

.form-location__main-location-searchable-main {
  color: green;
}
