
.logo {
    display: inline-block;

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        display: inline-block;
    }
}
